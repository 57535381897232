import React from "react";
import { Autocomplete, TextField } from "@mui/material";

/**
 *
 * @param {Object} dataSource to provide datasource in this format > `[ {id: 1, label: 'lable1'}. {id: 2, label: 'lable2'} ]`
 * @param {String} placeholder
 * @param {Event} selectedOption will return selected option in this format `{id: 1, label: 'lable'}`
 * @param {String} iniValue Initial value
 * @param {Boolean} error
 * @param {String} helperText Initial value
 * @param {Object} sx
 * @param {*} reset
 */
const Dropdown = ({ reset = false,selectedOption, dataSource, ...props }) => {
  const [defaultValue, setDefaultValue] = React.useState(props.iniValue);

  function handleOnChange(val) {
    setDefaultValue(val);
    selectedOption(val);
  }

  React.useEffect(() => {
    handleOnChange(defaultValue);
  }, []);

  React.useEffect(() => {
    setDefaultValue(props.iniValue);
  }, [props.iniValue]);

  React.useEffect(() => {
    if (reset) {
      handleOnChange(null);
    }
  }, [reset]);

  return (
    <Autocomplete
      multiple={props.multiple}
      value={defaultValue || null}
      // defaultValue= {props.dataSource.filter(val => val.label == props.iniValue?.label)[0] || null}
      fullWidth={props.fullWidth ? true : false}
      id={props.id}
      size="small"
      autoComplete={false}
      options={dataSource}
      onChange={(e, val) => handleOnChange(val)}
      onKeyDown={props.onKeyDown}
      sx={{ ...(props.sx ?? {}), margin: 0, padding: 0 }}
      readOnly={props.readOnly ?? false}
      renderInput={(params) => (
        <TextField
          label={props.label}
          placeholder={props.placeholder}
          inputProps={props.register}
          margin="none"
          autoComplete="nothing"
          error={Boolean(props.errMsg ?? "")}
          helperText={props.errMsg ?? ""}
          {...params}
        />
      )}
      noOptionsText={props.noOptionsText ?? "No options"}
      {...props}
    />
  );
};

export default Dropdown;
