import React from 'react';
import { DateRangePicker } from 'rsuite';
import './style.css';

const TYDateRangePicker = ({ reset, ...props }) => {
    // Remove `reset` if it's `false`
    const filteredProps = reset === false ? { ...props } : { reset, ...props };

    return <DateRangePicker {...filteredProps} />;
};

export default TYDateRangePicker;
